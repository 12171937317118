<template>
  <section class="w-full">
    <div v-if="loading" class="rounded-md icono-carga flex justify-center items-center">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)"/>
    </div>
    <div class="accordion-item" v-else>
      <div id="faq-accordion-content-1" class="accordion-header flex justify-between">
        <button
          class="accordion-button text-base font-medium focus:outline-none dark:text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#faq-accordion-collapse-1"
          aria-expanded="true"
          aria-controls="faq-accordion-collapse-1"
        >
          Filtros de busqueda
        </button>
        <div class="flex justify-end">
          <Button icon="pi pi-search" @click="updateFilter(filters)" class="p-button-sm p-button-info mr-2" v-tooltip.top="'Buscar'"/>
          <Button icon="pi pi-file-excel" class="p-button-sm p-button-success" @click="downloadExcel(filters)" v-tooltip.top="'Exportar Excel'"/>
        </div>
      </div>
      <div
        id="faq-accordion-collapse-1"
        class="accordion-collapse collapse show mt-2"
        aria-labelledby="faq-accordion-content-1"
        data-bs-parent="#faq-accordion-1"
      >
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label for="banks">Bancos:</label>
              <Dropdown
                id="banks"
                :options="banks"
                v-model="filters.bankId.value"
                class="p-inputtext-sm"
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="obligationNumber">Línea de Crédito:</label>
              <Dropdown
                id="obligationNumber"
                :options="creditLines"
                v-model="filters.creditLineId.value"
                class="p-inputtext-sm"
                optionLabel="name"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="flex flex-col sm:flex-row justify-between items-center border-t border-gray-300 dark:border-dark-5" ></div>
</template>

<script>

import { onMounted, ref } from 'vue'
import listBanks from '../../../services/listBanks'
import listCreditLines from '../../../services/listCreditLines'

export default {
  name: 'filtersReportCreditsStatus',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const loading = ref()
    const banks = ref()
    const creditLines = ref()

    /* Obtener el listado de bancos */
    const fetchBanks = () => {
      return listBanks({ options: true }).then(({ status, data }) => {
        banks.value = data.result
        banks.value.unshift({ id: '', name: 'Todos' })
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Obtener el listado de las lineas de credito */
    const fetchCreditLines = () => {
      return listCreditLines({ options: true }).then(({ status, data }) => {
        creditLines.value = data.result
        creditLines.value.unshift({ id: '', name: 'Todos' })
      }).catch(err => {
        console.error(err.message)
      })
    }

    const updateFilter = (e) => {
      context.emit('update:modelValue', e)
      context.emit('search', { filters: e })
    }

    const downloadExcel = (e) => {
      context.emit('update:modelValue', e)
      context.emit('downloadExcel', { filters: e })
    }

    onMounted(() => {
      fetchCreditLines()
      fetchBanks()
    })

    return {
      updateFilter,
      downloadExcel,
      fetchCreditLines,
      filters: props.modelValue,
      creditLines,
      status,
      loading,
      banks
    }
  }
}
</script>

<style scoped>

</style>
